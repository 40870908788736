import { Yes, No } from '@dfds-ui/icons/system'
import { ListIcon } from '@dfds-ui/react-components'
import styled from '@emotion/styled'

import { CardTextBlock, FlexCard } from '../Card'
import { gridMap } from '../../utils'

const StyledTable = styled.table`
  width: 100%;

  tr:not(:first-child) {
    border-top: 1px solid #cccccc;
  }
  th {
    font-weight: bold;
    padding: 12px 6px;
  }
  td {
    padding: 12px 0;
  }
  td:not(:first-child) {
    padding: 12px 6px;
    text-align: center;
  }

  .tooltip {
    position: relative;
    display: inline-block;
    cursor: default;
    color: #49a2df;
  }
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 150px;
    background-color: #baddf3;
    color: #4d4e4c;
    text-align: center;
    padding: 8px;
    border-radius: 6px;
    position: absolute;
    top: -4px;
    left: 25px;
    z-index: 1;
  }
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
`

const InfoBlockLoyaltyTiers = () => {
  return (
    <FlexCard width={gridMap['one-third']}>
      <CardTextBlock
        title={'Tiers & Benefits'}
        text={
          'Here are just some of the benefits you can enjoy as you build up your loyalty points and progress through the tiers.'
        }
      />
      <StyledTable>
        <tr>
          <th>&nbsp;</th>
          <th>Bronze</th>
          <th>Silver</th>
          <th>Gold</th>
        </tr>
        <tr>
          <td>Exclusive Member-Only Promotions</td>
          <td>
            <ListIcon icon={Yes} color={'#999999'} />
          </td>
          <td>
            <ListIcon icon={Yes} color={'#999999'} />
          </td>
          <td>
            <ListIcon icon={Yes} color={'#999999'} />
          </td>
        </tr>
        <tr>
          <td>Special Birthday Offers</td>
          <td>
            <ListIcon icon={Yes} color={'#999999'} />
          </td>
          <td>
            <ListIcon icon={Yes} color={'#999999'} />
          </td>
          <td>
            <ListIcon icon={Yes} color={'#999999'} />
          </td>
        </tr>
        <tr>
          <td>
            Flexible Ticket Options{' '}
            <span className="tooltip">
              &#9432;
              <span className="tooltiptext">
                Bronze members get a ticket flexibility of +/- 4 hours, silver
                members +/- 6 hours, and gold members +/- 8 hours.
              </span>
            </span>
          </td>
          <td>
            <ListIcon icon={Yes} color={'#999999'} />
          </td>
          <td>
            <ListIcon icon={Yes} color={'#999999'} />
          </td>
          <td>
            <ListIcon icon={Yes} color={'#999999'} />
          </td>
        </tr>
        <tr>
          <td>Early Access to Events</td>
          <td>
            <ListIcon icon={Yes} color={'#999999'} />
          </td>
          <td>
            <ListIcon icon={Yes} color={'#999999'} />
          </td>
          <td>
            <ListIcon icon={Yes} color={'#999999'} />
          </td>
        </tr>
        <tr>
          <td>
            Free Meals for Kids Onboard{' '}
            <span className="tooltip">
              &#9432;
              <span className="tooltiptext">
                Bronze members get offered a free meal for kids between 0-3
                years old, silver members between 0-6 years old and gold members
                between 0-8 years old.
              </span>
            </span>
          </td>
          <td>
            <ListIcon icon={Yes} color={'#999999'} />
          </td>
          <td>
            <ListIcon icon={Yes} color={'#999999'} />
          </td>
          <td>
            <ListIcon icon={Yes} color={'#999999'} />
          </td>
        </tr>
        <tr>
          <td>
            Complimentary Water in Restaurants{' '}
            <span className="tooltip">
              &#9432;
              <span className="tooltiptext">
                Bronze members you a complimentary bottle of water in
                restaurants. Silver members get 2 bottles of water and gold
                members get unlimited water in restaurants.
              </span>
            </span>
          </td>
          <td>
            <ListIcon icon={Yes} color={'#999999'} />
          </td>
          <td>
            <ListIcon icon={Yes} color={'#999999'} />
          </td>
          <td>
            <ListIcon icon={Yes} color={'#999999'} />
          </td>
        </tr>
        <tr>
          <td>
            Free Booking Amendments{' '}
            <span className="tooltip">
              &#9432;
              <span className="tooltiptext">
                Silver members get a possibility to amend your booking once and
                at least 24 hours before the departure. Gold members get a
                possibility to amend their booking twice and at least 24 hours
                before the departure.
              </span>
            </span>
          </td>
          <td>
            <ListIcon icon={No} color={'#cccccc'} />
          </td>
          <td>
            <ListIcon icon={Yes} color={'#999999'} />
          </td>
          <td>
            <ListIcon icon={Yes} color={'#999999'} />
          </td>
        </tr>
        <tr>
          <td>
            Early Check-In for Foot Passengers{' '}
            <span className="tooltip">
              &#9432;
              <span className="tooltiptext">
                Silver and gold members get a possibility to check-in 1 hour
                earlier as a foot passenger.
              </span>
            </span>
          </td>
          <td>
            <ListIcon icon={No} color={'#cccccc'} />
          </td>
          <td>
            <ListIcon icon={Yes} color={'#999999'} />
          </td>
          <td>
            <ListIcon icon={Yes} color={'#999999'} />
          </td>
        </tr>
        <tr>
          <td>
            Complimentary In-Cabin Water & Snacks{' '}
            <span className="tooltip">
              &#9432;
              <span className="tooltiptext">
                Silver members get a complimentary bottle of water and chocolate
                in the cabin. Gold members get 2 complimentary bottles of water,
                champagne and chocolate in the cabin.
              </span>
            </span>
          </td>
          <td>
            <ListIcon icon={No} color={'#cccccc'} />
          </td>
          <td>
            <ListIcon icon={Yes} color={'#999999'} />
          </td>
          <td>
            <ListIcon icon={Yes} color={'#999999'} />
          </td>
        </tr>
        <tr>
          <td>Welcome Drink Onboard</td>
          <td>
            <ListIcon icon={No} color={'#cccccc'} />
          </td>
          <td>
            <ListIcon icon={No} color={'#cccccc'} />
          </td>
          <td>
            <ListIcon icon={Yes} color={'#999999'} />
          </td>
        </tr>
        <tr>
          <td>VIP Customer Service Access</td>
          <td>
            <ListIcon icon={No} color={'#cccccc'} />
          </td>
          <td>
            <ListIcon icon={No} color={'#cccccc'} />
          </td>
          <td>
            <ListIcon icon={Yes} color={'#999999'} />
          </td>
        </tr>
        <tr>
          <td>Priority Boarding (Subject to Availability)</td>
          <td>
            <ListIcon icon={No} color={'#cccccc'} />
          </td>
          <td>
            <ListIcon icon={No} color={'#cccccc'} />
          </td>
          <td>
            <ListIcon icon={Yes} color={'#999999'} />
          </td>
        </tr>
      </StyledTable>
    </FlexCard>
  )
}

export default InfoBlockLoyaltyTiers
